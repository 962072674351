.toolbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgb(58, 58, 62);
  height: 86px;
}

.toolbar__navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
}

.toolbar__logo a {
  color: white;
  text-decoration: none;
  font-size: 2rem;
}

.toolbar_navigation-items a {
  color: white;
  text-decoration: none;
}

.toolbar__logo {
  margin: 1rem;
}

.spacer {
  flex: 1;
}

.toolbar_navigation-items {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.toolbar_navigation-items ul {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.toolbar_navigation-items li {
  padding: 0 2rem;
  font-size: 1.2rem;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
  color: #2b62a1;
  transition: 0.3s;
}

.searchInput {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.activeLink {
  color: #2196f3 
}

@media (max-width: 958px) {
  .toolbar_navigation-items {
    display: none;
  }
}

@media (max-width: 1339px) {
  .toolbar_navigation-items li {
    padding: 0 1rem;
    font-size: 1rem;
  }
}

@media (max-width: 1059px) {
  .toolbar_navigation-items li {
    padding: 0 0.5rem;
    font-size: 1rem;
  }
}

@media (min-width: 959px) {
  .toolbar__toggle-button {
    display: none;
  }

  .toolbar__logo {
    margin-left: 0;
  }
}
.paginationContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  margin-bottom: 2%;
}

.paginationContainer button {
  padding: 8px 16px;
  text-decoration: none;
  border: 1px solid #ddd;
  border-radius: 2px;
  color: white;
  background-color: #0f0f0f;
  cursor: pointer;
}

.paginationList {
  list-style: none;
  padding: 0;
  display: flex;
  margin-bottom: 2%;
}

.paginationList button:hover {
  background-color: #4caf50;
  color: white;
  border: 1px solid #4caf50;
}

.paginationList .active {
  background-color: rgb(59, 111, 255);
  color: white;
  /* border-radius: 5px; */
}

.side-drawer {
  height: 100%;
  background: #0f0f0f;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 35%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.7s ease-out;
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer li {
  margin: 0.5rem 0;
}

.side-drawer ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.side-drawer a {
  text-decoration: none;
  color: white;
  font-size: 2rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}

.side-drawer a:hover,
.side-drawer a:active {
  color: #fa923f;
}

.sideSearchInput {
  padding: 12px;
  margin-left: 10%;
  width: 80%;
  border: 1px solid #ccc;
  border-radius: 4px;
}

@media (min-width: 959px) {
  .side-drawer {
    display: none;
  }
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html {
  height: 100%;
}

.body {
  height: 100%;
  width: 100%;
  margin-top: 74px;
}

.PrincipalPage {
  background-color: #0f0f0f;
  height: 100%;
  width: 100%;
}

.header li {
  text-decoration: none;
  color: black;
}

.titreFilms {
  color: white;
  font-size: 22px;
  width: 100%;
  text-align: center;
  margin-top: -7%;
}
.flexBox {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 5%;
}

.cardFilm {
  position: relative;
  width: 200px;
  height: 300px;
  box-shadow: 0 30px 30px rgba(0, 0, 0, 0.5);
  margin-top: 3%;
  margin-left: 2%;
  margin-right: 2%;
  text-align: center;
}

.cardFilm img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.4s ease;
}

.playIcon {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.4s ease;
  background-color: rgba(0, 0, 0, 0.7);
}

.icon {
  color: white;
  font-size: 100px;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.cardFilm:hover .playIcon {
  opacity: 1;
  transform: scale(1.1);
  z-index: 2;
}

.cardFilm:hover img {
  transform: scale(1.1);
  filter: blur(1.3px);
}

.titres {
  color: white;
}

.titre {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}

.titreSection {
  color: white;
  font-size: 16px;
  width: 100%;
  text-align: center;
  margin-top: 2%;
}

.bottomFilm {
  display: flex;
  background-color: rgb(58, 58, 62); 
  height: 12%;
  width: 100%;
  position: absolute;
  bottom: 0;
  justify-content: space-around;
  align-items: center;
}

.note {
  display: flex;
  color: gold
}

.note p {
  color: white

}

.noFav {
  margin-top: 10%;
  color: white
}

.star {
  color: white;
}

.titleNoResults {
  font-size: 30px;
  font-weight: 300;
  font-family: "Open Sans", sans-serif;
  color: white;
}

.noResults {
  margin-top: 15%;
}

.waitingDiv {
  margin-top: 30vh;
}
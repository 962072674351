.flexBox {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 5%;
  align-items: stretch;
}

.videoContainer {
  margin-top: 2%;
  width: 59.2%;
  height: 70%;
  text-align: center;
}

.videoContainer img {
  width: 100%;
  max-width: 900px;
  height: auto;
}

.videoContainer iframe {
  left: 0;
  top: 0;
  /* height: 100%; */
  width: 100%;
}

/* .videoContainer:hover img {
  filter: blur(1.3px);
} */

.titres {
  color: white;
}

.filmTitle {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: white;
  font-size: 42px;
  margin-bottom: 3%;
}

.infosDiv {
  width: 80%;
  margin-top: 2%;
  background: linear-gradient(to right, #090909, #0f0f0f);
  border-radius: 6px;
  border: 1px solid #262424;
  margin-bottom: 3%;
  padding-bottom: 2%;
  margin-top: 5%;
}

.filmsOverview {
  color: white;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 2%;
  padding-bottom: 2%;
  border-bottom: 1px solid #343434;
  text-align: justify;
}

.titleImage {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: white;
}